<template>
    <div class="col">
        <div class="row d-flex align-center m-0">
            <div class="col-12 pa-0">
                <v-dialog v-model="dialog" width="400" @click:outside="tab = 0" class="">
                    <template v-slot:activator="{ on }">
                        <v-text-field :value="localeDate" v-bind="mergeTextfieldProps" v-on="on" hide-details="auto"
                            class="pt-0 mt-0">
                        </v-text-field>
                    </template>
                    <v-card>
                        <v-card-text class="p-0">
                            <v-tabs fixed-tabs v-model="tab">
                                <v-tabs-slider color="blue"></v-tabs-slider>
                                <v-tab>
                                    <slot name="dateTab">
                                        <v-icon>mdi-calendar-clock</v-icon>
                                        <span>Fecha</span>
                                    </slot>
                                </v-tab>
                                <v-tab>
                                    <slot name="timeTab">
                                        <v-icon>mdi-calendar-clock</v-icon>
                                        <span>Hora</span>
                                    </slot>
                                </v-tab>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item class="flex">
                                        <v-date-picker :allowed-dates="allowedDates" v-model="date" v-if="dialog"
                                            @input="onDateInput" class="col w-100 p-0"
                                            v-bind="mergeDateProps"></v-date-picker>
                                    </v-tab-item>
                                    <v-tab-item class="flex">
                                        <v-time-picker v-model="time" v-if="dialog" v-bind="mergeTimeProps"
                                            class="col w-100 p-0"></v-time-picker>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <slot name="actions">
                                <v-btn :color="btns.cancel.color" text @click="dialog = false">{{ btns.cancel.text
                                }}</v-btn>
                                <v-btn :color="btns.next.color" text @click="onNext">{{
                                    btns.next.text
                                }}</v-btn>
                            </slot>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "DateTimePicker",
    mounted() {
        //set this.date with this.datetime in iso format
        this.date = this.datetime.toISOString().slice(0, 10);
        this.time = this.toLocalISOString(this.datetime).slice(11, 16)
        this.$emit('datetime', this.toLocalISOString(this.datetime));
    },
    data() {
        return {
            datetime: new Date(),
            date: null,
            time: null,
            tab: null,
            dialog: false,
            allowedDates: val => val <= new Date().toISOString().slice(0, 10),
            btns: {
                cancel: {
                    text: "Cancelar",

                    color: "danger",
                    disabled: false,
                },
                next: {
                    text: "Siguiente",
                    color: "primary",
                    disabled: false,
                },
            },

            defaultDateProps: {
                "first-day-of-week": 1,
                locale: "es-ES",
            },
            defaultTimeProps: {
                "use-seconds": true,
                format: "24hr",
                scrollable: true,
            },
            defaultTextfieldProps: {
                "prepend-inner-icon": "mdi-calendar-clock",
            },
        };
    },
    props: {
        dateProps: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        timeProps: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        textfieldProps: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        //   input: {
        //     type: Object,
        //     required: true,
        //   },
    },
    methods: {
        onDateInput() {
            if (this.tab == 0) {
                this.tab = 1;
            }
        },
        onNext() {
            if (this.tab == 0) {
                this.tab = 1;
            } else {
                this.datetime = new Date(this.date + " " + this.time);
                this.$emit('datetime', this.toLocalISOString(this.datetime));
                this.dialog = false;
            }
        },
        toLocalISOString(date) {
            const offset = date.getTimezoneOffset() * 60000;
            const adjustedDate = new Date(date.getTime() - offset);
            const isoString = adjustedDate.toISOString().slice(0, -1);

            const offsetHours = Math.floor(Math.abs(offset) / 3600000);
            const offsetMinutes = Math.floor((Math.abs(offset) % 3600000) / 60000);

            const formattedOffset = (offset > 0 ? '-' : '+') +
                String(offsetHours).padStart(2, '0') + ':' +
                String(offsetMinutes).padStart(2, '0');

            return isoString + formattedOffset;
        }
    },
    computed: {
        localeDate() {
            return this.datetime.toLocaleString("es-CL")
        },
        mergeDateProps() {
            return { ...this.defaultDateProps, ...this.dateProps };
        },
        mergeTimeProps() {
            return { ...this.defaultTimeProps, ...this.timeProps };
        },
        mergeTextfieldProps() {
            return { ...this.defaultTextfieldProps, ...this.textfieldProps };
        },
    },
    watch: {
        dialog(newval, oldval) {
            if (!newval) {
                this.tab = 0;
            }
        },
    },
};
</script>
  
<style lang="scss" scoped></style>