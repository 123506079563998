<template>
  <v-container>
    <v-row class="matriz-row-container">
      <v-col cols="12">
        <v-row class="export-container" align-content="center">
          <div class="text-div">Seleccionar rango de fechas:</div>
          <date-time-picker @datetime="setDatetime" />
          <export-devices-measurements :devices-ids="devicesIds" :to="datetime"></export-devices-measurements>
        </v-row>
        <v-row justify="space-between">
          <v-col cols="4">
            <v-row justify="center" class="pl-15 mb-15">
              <h3>{{ nodeId ? `Mostrando datos de nodo ${nodeLabel}` : `Seleccione nodo para mostrar información` }}</h3>
            </v-row>
            <v-row justify="center" class="fill-height">
              <pucobre-matriz @node-id="getDeviceDisplayers"></pucobre-matriz>
            </v-row>
          </v-col>
          <v-col cols="7" v-if="deviceDisplayers && nodeId">
            <device-data :displayers="deviceDisplayers" :data-background-color="getTheme" :nodeId="nodeId" :key="dataForceKey"
              class="monitoring-container" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import PucobreMatriz from '../components/Monitoring/Matriz/PucobreMatriz.vue';
import ExportDevicesMeasurements from '../components/Exports/ExportDevicesMeasurements.vue';
import DeviceData from "../components/Devices/DeviceData";
import DateTimePicker from '../components/UI/DateTimePicker.vue';
import { mapGetters } from "vuex";
export default {
  components: {
    PucobreMatriz,
    DeviceData,
    ExportDevicesMeasurements,
    DateTimePicker
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  data: () => ({
    deviceDisplayers: null,
    nodeId: null,
    nodeLabel: '',
    pucobreRowContainer: 500,
    devicesIds: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72],
    datetime: new Date(),
    dataForceKey: 0
  }),
  methods: {
    getDeviceDisplayers(nodeId, nodeLabel) {
      console.log(nodeId);
      this.$store
        .dispatch("displayers/getDeviceDisplayers", nodeId)
        .then((data) => {
          this.deviceDisplayers = data;
          this.nodeId = nodeId;
          this.nodeLabel = nodeLabel;
    this.dataForceKey += 1;
        });
    },
    setDatetime(datetime) {
      this.datetime = datetime;
    },
  },
};
</script>
<style lang="sass" scoped>
  .matriz-row-container
    height: 60vh
  .export-container
    height: 50px
  .text-div
    font-size: 16px
    font-weight: 500
    height: 20px
    display: flex
    align-self: center
  </style>