<template>
  <!-- <md-button @click="exportCSV">Descargar CSV</md-button> -->
  <v-btn @click="exportCSV" color="primary" class="white--text">
    Descargar CSV
  </v-btn>
</template>

<script>
import api from '../../services/device.api'
export default {
  name: "ExportDevicesMeasurements",
  props: {
    devicesIds: {
      type: Array,
      default: () => [],
    },
    to: {
      type: String,
    },
  },
  computed: {
    from() {
      const date = new Date(this.to)
      let from = date.getTime() - 15 * 60000;
      return this.toLocalISOString(new Date(from))
    },
  },
  methods: {
    async exportCSV(e) {
      e.preventDefault();
      try {
        const { data } = await api.getDevicesMeasurementsByIds(this.devicesIds, this.from, this.to)
        if (data) {
          const blob = new Blob([data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", "mediciones.csv");
          link.click()
        }
      } catch (error) {
        console.error(error);
      }
    },
    toLocalISOString(date) {
      const offset = date.getTimezoneOffset() * 60000;
      const adjustedDate = new Date(date.getTime() - offset);
      const isoString = adjustedDate.toISOString().slice(0, -1);

      const offsetHours = Math.floor(Math.abs(offset) / 3600000);
      const offsetMinutes = Math.floor((Math.abs(offset) % 3600000) / 60000);

      const formattedOffset = (offset > 0 ? '-' : '+') +
        String(offsetHours).padStart(2, '0') + ':' +
        String(offsetMinutes).padStart(2, '0');

      return isoString + formattedOffset;
    }
  },
};
</script>